@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../fonts/futura-pt-book/style.css';
@import '../fonts/futura-pt-demi/style.css';
// ----- Vars:
$pretty--color-primary: #ff6500;
$color-light: #e5e7eb;
$prefix: 'gtt';
// -----
@import '~pretty-checkbox/src/pretty-checkbox';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/translucent.css';
@import '~tippy.js/themes/light-border.css';
@import '~tippy.js/animations/shift-away.css';
@import '~react-datepicker/dist/react-datepicker.min.css';
@import '~tippy.js/animations/scale.css';

@import 'component/layout';
@import 'component/form';
@import 'component/table';
@import 'component/pagination';
@import 'component/dragndrop';

body {
  @apply font-body text-gray-800 bg-light;
  font-size: 18px;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  overflow-y: hidden;
}

body,
html,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-display;
  font-weight: bold;
  font-style: normal;
}

h1 {
  @apply text-3xl;
}

h2,
h3,
h4 {
  @apply text-2xl;
}

h5,
h6 {
  @apply text-xl;
}

::selection {
  @apply bg-primary;
  color: #fff;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

