@font-face {
    font-family: 'Futura PT Book';
    src: url('./FuturaPT-Book.eot');
    src: url('FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
    url('FuturaPT-Book.woff2') format('woff2'),
    url('FuturaPT-Book.woff') format('woff'),
    url('FuturaPT-Book.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
