.field-mb {
  @apply mb-8;
}
.sm-field-mb {
  @apply mb-3;
}
.item-container {
  @apply py-2 px-3;
  @screen md {
    @apply py-3 px-5;
  }
}
.n-item-container {
  @apply -my-2 -mx-3 mb-2;
  @screen md {
    @apply py-3 px-5;
  }
}
.block-container {
  @apply py-3 px-5;
  @screen md {
    @apply py-5 px-10;
  }
}
.n-block-container {
  @apply -my-3 -mx-5 mb-3;
  @screen md {
    @apply -my-5 -mx-10 mb-5;
  }
}
.block-with-sub-container {
  @apply py-3 px-5;
  @screen md {
    @apply py-5 px-10;
  }
  background: rgba(243, 244, 246, 255);
  border: 1px solid rgba(216, 218, 222, 255);
}
.section-container {
  @apply py-8 px-5;
  @apply shadow;
  @screen md {
    @apply py-16 px-10;
  }
}
.n-section-container {
  @apply -my-8 -mx-5 mb-8;
  @screen md {
    @apply -my-16 -mx-10 mb-16;
  }
}
.container-shadow {
  @apply shadow;
}
#dashboard_slider {
  scrollbar-color: #e5e7eb rgba(0, 0, 0, 0.02);
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7eb;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.02);
  }
}
#carousel_slider {
  scrollbar-color: rgba(255, 141, 86, 0.84) rgba(0, 0, 0, 0.02);
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100vh;
    background: rgba(241, 140, 92, 0.78);
  }
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ff6500;
  }
}
.pathways-section {
  @apply mb-5 p-2;
}
.pathways-section-content {
  @apply mt-3;
}
