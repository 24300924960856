@font-face {
    font-family: 'Futura PT Demi';
    src: url('./FuturaPT-Demi.eot');
    src: url('FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
    url('FuturaPT-Demi.woff2') format('woff2'),
    url('FuturaPT-Demi.woff') format('woff'),
    url('FuturaPT-Demi.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
