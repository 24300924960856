/* For the next transition */
.slide-next-enter {
    opacity: 0;
    transform: translateX(30%);
}

.slide-next-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    position: absolute;
    display: flex;
}

.slide-next-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-next-exit-active {
    opacity: 0;
    transform: translateX(-30%);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    position: absolute;
    display: flex;
}

/* For the previous transition */
.slide-prev-enter {
    opacity: 0;
    transform: translateX(-30%);
}

.slide-prev-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    position: absolute;
    display: flex;
}

.slide-prev-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-prev-exit-active {
    opacity: 0;
    transform: translateX(30%);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    position: absolute;
    display: flex;
}

.slide-content {
    width: 100%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.slider-container {
    position: relative;
    width: 100%;
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 20px;
        height: 20px;
    }

    .scrollbar::-webkit-scrollbar-track {
        margin-top: 10px;
        border-radius: 100vh;
        background: #f3e9dc;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #e4c494;
        border-radius: 100vh;
        border: 3px solid #e4c494;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #dca75a;
    }
}

/*.customButtonHover {
    background-color: #98E288 !important;
}

.customButtonHover:hover {
    background-color: #50f12c !important;

}

.disabledButtonHover {
    background-color: #dff3cc !important;
}

.disabledButtonHover:hover {
    background-color: #dff3cc !important;
}*/
