input:disabled,
.gtt__control--is-disabled {
  @apply text-muted bg-gray-100 border-light #{!important};
}
.pretty-no-margin {
  margin: 0 !important;
  label {
    margin-left: 1.25rem !important;
  }
}
.chargebee-field {
  background: white;
  .chargebee-input {
    @apply border border-secondary;
    outline: none;
    padding: 10px 15px;
    vertical-align: middle;
    line-height: 1.5;
    transition: box-shadow ease-in-out 450ms, border-color ease-in-out 450ms;
    &.CbHosted--focus {
      @apply border-primary;
      box-shadow: 0 0 0 5px rgba(255, 101, 0, 0.3);
    }
  }
  &,
  .chargebee-input {
    @apply rounded-app;
    min-height: 40px;
  }
}
