.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item {
  display: list-item;
}

.page-item.disabled {
  color: #6c757d;
  pointer-events: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  text-decoration: none;
  border: 1px solid #dee2e6;
}

.page-item.active {
  color: $color-light;
  background-color: $pretty--color-primary;
  border-bottom-color: #ffa366;
}


