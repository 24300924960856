$padding: 15px;
$cell--padding: $padding calc(#{$padding} / 2);
@mixin addCellShadow($color: $color-light) {
  box-shadow: inset 0 0 0 1px $color;
}
.more-actions-list {
  li button {
    @apply border-b border-light py-3 px-2;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1rem;
    &:hover {
      @apply bg-light;
    }
  }
  li:last-child button {
    border: none;
  }
}
.#{$prefix}-table {
  border: 1px solid $color-light;
  width: 100%;
  cursor: default;
  .no-results {
    @apply text-muted;
    text-align: center;
    font-style: italic;
    [role='cell'] {
      padding: $cell--padding;
    }
  }
  // DEFAULT
  &.table--default {
    background-color: #ffffff;
    .th--status,
    .td--status,
    .th--actions,
    .td--actions {
      text-align: center;
      width: 110px;
      .actions-list {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          @apply border-r border-light mr-3 pr-3;
        }
        li:last-child {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
    }
    thead {
      @apply bg-light;
      text-align: left;
      font-size: 0.95rem;
      [role='cell'] {
        @include addCellShadow(#ffffff);
        padding: $cell--padding;
      }
    }
    tbody {
      .td--top {
        vertical-align: top;
      }
      [role='row'] {
        transition: background-color 150ms ease-in-out;
        &.row--archived {
          @apply bg-light bg-opacity-25 text-muted opacity-75;
          font-style: italic;
        }
        &:hover {
          @apply bg-light bg-opacity-50;
        }
        [role='cell'] {
          @include addCellShadow;
          padding: $cell--padding;
          &[rowspan]:not([rowspan='']) {
            vertical-align: top;
          }
        }
      }
    }
  }
  &.table--no-hover {
    tbody [role='row']:hover {
      @apply bg-transparent;
    }
  }
  // NO HOVER
  // STICKY
  &.table--sticky {
    position: relative;
    thead {
      @apply z-50 shadow;
      position: sticky;
      top: 0;
    }
  }
}
